import React from 'react';
export default function Privacy() {
return (
  <section className="termos-condicoes__wrapper">
        <h1>Cookies</h1>
          <p>
          O site da Spider Tattoos (como a maioria dos sites na Internet) utiliza cookies para funcionar convenientemente. Alguns destes cookies são necessários para que possa submeter um formulário, por exemplo, entre outras funcionalidades. Estes cookies identificam-no como um utilizador individual enquanto navega pelo website, mas não o identificam pessoalmente.
          Se não estiver à vontade com esta opção pode recusar-se a utilizar os cookies, mas algumas das funcionalidades do site poderão não funcionar correctamente.
          Também usamos cookies não essenciais de entidades terceiras que permitem monitorizar o acesso ao site e melhorar a sua experiência futura, bem como para fornecer funcionalidades adicionais. Para perceber melhor a forma como utilizamos a monitorização do website e de que forma usamos informação recolhida consulta a nossa <a href=" /tcs/politica-privacidade">política de privacidade</a>.
          A maioria dos navegadores de Internet permitem-lhe controlar a utilização de cookies através das preferências de navegação. Para saber mais sobre cookies e controlar o seu uso visite, por exemplo, os sites <a href="www.aboutcookies.org">www.aboutcookies.org</a> ou <a href="www.allaboutcookies.org">www.allaboutcookies.org</a>.
          </p>
  </section>

)};
