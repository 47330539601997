import React from "react";
import Tattoos from "./Text/Tattoos";
import Piercings from "./Text/Piercings";
import Healing from "./Text/Healing";
import "./Care.scss";

export default function Care() {
  const [dialog, setDialog] = React.useState(null);
  const tattoosButtonRef = React.useRef();
  const piercingsButtonRef = React.useRef();
  const healingButtonRef = React.useRef();

  return (
    <section className="care">
      <h1 className="section-title">Cuidados a ter</h1>
      <dialog className="spidertattoos-dialog" ref={tattoosButtonRef}>
        <div className="wrapper">
          <nav>
            <button
              className="dialog-close"
              onClick={() => tattoosButtonRef?.current?.close()}
            >
              X
            </button>
          </nav>

          <section>
            <Tattoos />
          </section>
        </div>
      </dialog>
      <dialog className="spidertattoos-dialog" ref={piercingsButtonRef}>
        <div className="wrapper">
          <nav>
            <button
              className="dialog-close"
              onClick={() => piercingsButtonRef?.current?.close()}
            >
              X
            </button>
          </nav>
          <section>
            <Piercings />
          </section>
        </div>
      </dialog>
      <dialog className="spidertattoos-dialog" ref={healingButtonRef}>
        <div className="wrapper">
          <nav>
            <button
              className="dialog-close"
              onClick={() => healingButtonRef?.current?.close()}
            >
              X
            </button>
          </nav>
          <section>
            <Healing />
          </section>
        </div>
      </dialog>
      <div className="cuidados-a-ter__button__wrapper">
        <button
          className="care spidertattoos-button"
          onClick={() => tattoosButtonRef?.current?.showModal()}
        >
          Tatuagens
        </button>

        <button
          className="care spidertattoos-button"
          onClick={() => piercingsButtonRef?.current?.showModal()}
        >
          Piercings
        </button>

        <button
          className="care healing spidertattoos-button"
          onClick={() => healingButtonRef?.current?.showModal()}
        >
          Tempo de Cicatrização de Piercings
        </button>
      </div>
    </section>
  );
}
