import React from 'react';
export default function Healing() {
    return (
      <>
        <h1>
TEMPO DE CICATRIZAÇÃO DE PIERCINGS
</h1>
        <h2>ORELHA</h2>
          <ul>
            <li>Lóbulo - 2 a 3 meses</li>
            <li>Hélice - 12 a 18 meses</li>
            <li>Industrial - 12 a 18 meses</li>
            <li>Trago - 12 a 18 meses</li>
            <li>Anti-Trago - 12 a 18 meses</li>
            <li>Daith - 12 a 18 meses</li>
            <li>Rook - 12 a 18 meses</li>
            <li>Snug - 12 a 18 meses</li>
            <li>Concha - 12 a 18 meses</li>
          </ul>

          <h2>FACE</h2>
          <ul>
            <li>Narina - 6 a 8 meses</li>
            <li>Earl - 8 a 12 meses</li>
            <li>Sobrolho - 4 a 6 meses</li>
            <li>Septo - 4 a 6 meses</li>
            <li>Medusa - 6 a 8 meses</li>
            <li>Madonna - 6 a 8 meses</li>
            <li>Labret - 6 a 8 meses</li>
            <li>Labret Vertical - 6 a 8 meses</li>
            <li>Língua - 2 a 3 meses</li>
            <li>Bochecha - 6 a 8 meses</li>
            <li>Smiley - 1 a 2 meses</li>
            <li>Freio da Língua - 1 a 2 meses</li>
          </ul>

          <h2>TRONCO</h2>
          <ul>
            <li>Mamilo - 12 a 18 meses</li>
            <li>Umbigo - 12 a 18 meses</li>
            <li>Superficial - ... </li>
            <li>Skin Dive - 5 a 6 meses</li>
            <li>Microdermal - 5 a 6 meses</li>
          </ul>

          <h2>GENITAL MASCULINO</h2>
          <ul>
            <li>Prince Albert - 2 a 3 meses</li>
            <li>P.A. Invertido - 3 a 5 meses</li>
            <li>Ampallang - 8 a 12 meses</li>
            <li>Apadravya - 8 a 12 meses</li>
            <li>Prepúcio - 6 a 8  meses</li>
            <li>Freio - 4 a 6 meses</li>
            <li>Lorum - 6 a 8 meses</li>
            <li>Hafada - 4 a 6 meses</li>
            <li>Guiche - 8 a 12 meses</li>
          </ul>

          <h2>GENITAL FEMININO</h2>
          <ul>
            <li>Capuz do Clitóris - 2 a 3 meses</li>
            <li>Christina - 8 a 12 meses</li>
            <li>Lábios Interiores - 4 a 6 meses</li>
            <li>Lábios Esteriores - 6 a 12 meses</li>
            <li>Fourchette - 2 a 3  meses</li>
          </ul>

          <p className="strong">IMPORTANTE: O Tempo de Cicatrização referido é APROXIMADO, uma vez que a Cicatrização depende de diversos factores, entre os quais, a higiene e o cuidado que tiver com o seu piercing. Consulte a nossa nota sobre "Cuidados a ter com o seu piercing"
          Qualquer dúvida não hesite em contactar-nos. ;)</p>
      </>
 )
}
