import React from "react";
import InstagramList from "./List/List";
import Care from "./Care/Care";
import Store from "./Store/Store";
import Privacy from "./Privacy/Privacy";

import "./App.scss";

const InstagramHeader = () => null;
const InstagramLoadMore = () => null;

const App = () => {
  const [state, setState] = React.useState({
    data: { items: [] },
    open: null,
    loadedIds: [],
    itemsCount: 0,
    currentOffset: 0,
    tag: null,
    loaded: false,
  });

  return (
    <>
      <section
        className={
          state?.loaded
            ? "row instagram-row-wrapper"
            : "row instagram-row-wrapper hide"
        }
      >
        <InstagramList />
      </section>
      <Care />
      <Store />
      <Privacy />
    </>
  );
};

export default App;
