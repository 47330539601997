import React from "react";

export default function Piercings() {
  return (
    <>
      <h1>Piercings</h1>
      <p className="cuidados-a-ter__modal_lead lead">
        CUIDADOS A TER COM O SEU PIERCING
      </p>

      <h2>Piercings em geral</h2>
      <p className="cuidados-a-ter__modal__callout">
        NUNCA TOQUE NO PIERCING COM AS MÃOS POR LAVAR!!!
      </p>
      <p>
        Limpe o piercing 2 a 3 vezes por dia usando: - SABÃO ANTI-BACTERIANO
        líquido sem fragrâncias e SOLUÇÃO SALINA.
      </p>

      <h3>SOLUÇÃO SALINA:</h3>
      <p>
        Dissolva um máximo de 1 colher de café de sal marinho para 500ml de água
        fervida. Tenha cuidado com as proporções! Mais salgado não irá limpar
        melhor; apenas vai irritar o seu piercing. Não use sal refinado!
      </p>

      <h3>COMO LIMPAR:</h3>

      <p>
        Lave as mãos antes de limpar o piercing. Aplique uma porção do tamanho
        de uma ervilha de SABÃO ANTI-BACTERIANO líquido no piercing e jóia.
        Enxague bem para remover todos os resíduos de espuma. Embeba o piercing
        durante 2 a 5 minutos com SOLUÇÃO SALINA, usando uma compressa ou um
        pedaço de papel (p.ex.: rolo de cozinha).
      </p>
      <p className="cuidados-a-ter__modal__callout">NÃO USE ALGODÃO!!!</p>

      <p>
        Remova com cuidado todo o plasma que possa ter secado na pele e limpe
        outra vez usando uma nova compressa embebida em água. Seque o piercing
        com uma compressa limpa e deixe-o exposto ao ar. Não aplique qualquer
        tipo de creme, pomada, anti-séptico ou cosméticos (sabão, champô,
        maquilhagem)durante todo o processo de cicatrização; estes são para uso
        externo e causam irritação caso entrem debaixo da pele.
      </p>

      <p className="cuidados-a-ter__modal__callout">
        EVITE QUALQUER IRRITAÇÃO:
      </p>
      <ul>
        <li> Algo que friccione o piercing</li>
        <li> Brincar/abusar de um piercing por cicatrizar.</li>
        <li>
          {" "}
          Mexa no piercing o menos possível até ao processo de cicatrização
          estar concluído. Os piercings muitas vezes parecem cicatrizados pelo
          exterior, mas podem ainda estar a cicatrizar no interior da pele.
          Mudar a jóia demasiado cedo pode causar uma infecção ou mesmo
          rejeição. O ideal é manter a jóia que foi usada para fazer o piercing
          durante TODO o processo de cicatrização!
        </li>
      </ul>

      <h2>Piercings orais</h2>

      <p className="cuidados-a-ter__modal__callout">
        NUNCA TOQUE NO PIERCING COM AS MÃOS POR LAVAR!!!
      </p>

      <p>
        A jóia inicial para piercings orais é sempre consideravelmente maior
        para permitir acomodar o inchaço. Normalmente pode ser substituída ao
        fim de 4 semanas. NÃO podemos mudar a jóia antes das 4 semanas, uma vez
        que pode causar mais inchaço e atrasar o processo de cicatrização.
      </p>

      <p>
        Um piercing oral deve ser enxaguado com um ELIXIR ORAL ANTI-BACTERIANO e
        SEM ÁLCOOL a seguira TODAS as refeições, bebidas e, caso seja fumador, a
        seguir a TODOS os cigarros. Tente bochechar tantas vezes quanto
        possível. Evite um elixir oral demasiado forte; pode ser um irritante e
        causar problemas com a cicatrização. Também pode usar solução salina
        para bochechar em vez do elixir oral.
      </p>

      <p>Durante pelo menos 4 semanas deve EVITAR:</p>
      <ul>
        <li> Comida picante/ácida;</li>
        <li> Bebidas com gás;</li>
        <li> Sumos de fruta naturais;</li>
        <li> Álcool.</li>
      </ul>

      <p>
        Estes produtos podem causar mais inchaço, irritar o piercing e prolongar
        o processo de cicatrização. Opte por comida suave e macia: puré, arroz
        ou massa bem cozidos, iogurtes, sopas. Tudo o que seja fácil de mastigar
        e engolir. Caso o piercing esteja tanto no interior como no exterior da
        sua boca (Labret, Madonna, bochecha, etc.), o exterior do piercing deve
        também ser limpo de acordo com os procedimentos atrás referidos para
        outros piercings em geral.
      </p>

      <p>
        Os piercings muitas vezes reagem de forma diferente de pessoa para
        pessoa. Caso o inchaço cause demasiada dor ou desconforto o seu médico
        poderá aconselhar-lhe um analgésico. Não pratique nenhuma actividade que
        possa causar a transferência de fluidos corporais com outra pessoa
        (beijar, sexo oral, etc.) durante, pelo menos, 4 semanas.
      </p>

      <h2> piercings genitais</h2>

      <p className="cuidados-a-ter__modal__callout">
        NUNCA TOQUE NO PIERCING COM AS MÃOS POR LAVAR!!!
      </p>

      <p>
        A melhor forma de tratar piercings genitais masculinos (Prince Albert,
        P.A. Reverso, Ampallang, Apadravya, etc.) é enxaguá-los com uma
        compressa embebida em SOLUÇÃO SALINA, 2 a 3 vezes por dia e também
        depois do banho.
      </p>

      <p>
        Os piercings genitais femininos devem ser limpos como qualquer outro
        piercing em geral, mas usando uma maior quantidade de SOLUÇÃO SALINA
        para garantir a remoção de qualquer vestígio de fluidos corporais do
        piercing e pele envolvente.
      </p>

      <p>
        Relações sexuais devem ser EVITADAS durante 2 a 3 semanas, e
        posteriormente devem ser usados APENAS preservativos como protecção
        durante TODO o processo de cicatrização!!!
      </p>

      <p>
        NÃO aplique nenhum tipo de creme, pomada, anti-séptico ou cosmético
        (sabão, gel de banho ou gel íntimo) durante todo processo de
        cicatrização. Estes são para uso externo e causam irritação caso entrem
        debaixo da pele. Não use roupa interior demasiado justa ou o que possa
        fazer fricção ou pressão sobre o piercing.
      </p>

      <h2>Implantes dermais</h2>

      <p className="cuidados-a-ter__modal__callout">
        NUNCA TOQUE NO PIERCING COM AS MÃOS POR LAVAR!!!
      </p>

      <p>
        A âncora é uma pequena peça de titânio com pequenas aberturas redondas.
        É inserida debaixo da pele e mantida no lugar assim que a pele adere
        através das aberturas.A outra parte do microdermal é a jóia que fica à
        vista.
      </p>

      <p>
        Como qualquer outro piercing superficial, o microdermal tem o risco de
        rejeição ou migração. Se suspeita de rejeição ou migração, o microdermal
        deve ser removido por um body piercer profissional assim que possível.
      </p>

      <p>
        Os cuidados com os implantes dermais são bastante simples: 2a 3 vezes
        por dia limpe da mesma forma que qualquer outro piercing (ver cuidados
        com piercings em geral), mas em vez de solução salina use ÁGUA QUENTE
        PRÉ-FERVIDA. Seque sempre o seu piercing com uma compressa limpa e
        deixe-o exposto ao ar. Evite irritações na zona do piercing, bem como
        qualquer tipo de cosmético.
      </p>

      <p className="strong">
        Estes cuidados são muito importantes para a cicatrização do seu piercing
        . A responsabilidade por este trabalho é sua. Cuide bem do seu piercing!
        Não deixe de consultar a nossa nota sobre o "tempo de cicatrização".
        qualquer dúvida não hesite em contactar-nos. ;)
      </p>
      <p></p>
    </>
  );
}
