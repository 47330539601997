import React from 'react';
export default function Privacy() {
return (
  <section className="termos-condicoes__wrapper">
          <h1>Política de Privacidade</h1>
          <p>
            A Spider Tattoos sabe que se preocupa com a sua privacidade e por isso agradecemos a sua confiança.
            Esta página descreve a nossa política de privacidade e indica o tipo de informação que recolhemos e utilizamos quando visita o nosso website ou quando interage connosco através de outros meios. Ao visitar o website da Spider Tattoos está a aceitar estas práticas.
            A informação recolhida tem como objectivo a melhoria  e a personalização contínua da forma como comunicamos com os utilizadores do nosso website e com a sua experiência no nosso website.
            À semelhança do que fazem muitos outros sites, a Spider Tattoos guarda automaticamente várias informações através de <a href="/tcs/cookies">“cookies”</a> quando o seu navegador acede ao nosso website, a anúncios ou a outros tipos de conteúdo fornecidos pela ou em nome da Spider Tattoos.
            Exemplos deste tipo de informação que recolhemos e analisamos podem incluir, mas não estão limitados a: endereço IP, endereço de e-mail, informação sobre o computador, navegador de Internet e sistema operativo, o fluxo de navegação para, durante e do nosso website, incluindo dia e hora, número de cookie, páginas que visualizou.
            Durante a navegação pelo website poderemos usar ferramentas informáticas como JavaScript para medir e recolher informação como o tempo de resposta das páginas, erros de navegação, duração do tempo de visita a certas páginas, informação sobre a interacção com a página (como scrolling, clicks e mouse-overs) e metódos usados para sair da página. Poderemos recolher informação técnica que nos permitam identificar o seu dispositivo como forma de prevenção de fraude e para diagnóstico.
            Recolhemos ainda, de uma forma que não nos permite identificar pessoalmente, e através das ferramentas de Publicidade da Google, informação sobre demografia e interesses dos nosso utilizadores. Caso não se sinta confortável com esta opção, pode sempre negar-se a transmitir esta informação, dando essa indicação, por exemplo, na página do <a href="http://www.networkadvertising.org/choices/">Network Advertising Choices</a> ou instalando o <a href="https://tools.google.com/dlpage/gaoptout/">plugin da Google</a>.
            A informação fornecida pelos nossos clientes e visitantes só é partilhada com terceiros absolutamente necessários e para garantir a prestação de serviços relacionados com a nossa área de negócio e a sua constante melhoria.
            Exemplos incluem, mas não estão limitadas, a envio de correio electrónico, a análise de dados, a assistência de marketing, o fornecimento de resultados de pesquisa (incluindo links pagos) e a assistência ao cliente. Estes serviços terceiros têm acesso apenas à informação pessoal necessária para realizar as suas funções, não as podendo usar de nenhuma outra forma.
            Utilizamos ainda cookies de entidades terceiras como o Facebook, ou a AddThis para podermos utilizar plugins que nos permitem oferecer ícones de partilha em redes sociais, a indicação de quais amigos do Facebook também gostam da página da Spider Tattoos, entre outras funcionalidade. Estes cookies são utilizados por estas entidades e podem identificá-lo(a) enquanto usa a Internet. Se quiser saber mais sobre cookies visite a nossa página sobre <a href="/tcs/cookies">cookies</a>.
          </p>
          <p className="strong">Qualquer dúvida contacte-nos. Obrigado.</p>

  </section>

)};
