import React from "react";
import "./Modal.scss";
export default function Modal({
  detailDialogRef,
  portfolio,
}: {
  detailDialogRef: any;
  portfolio: any;
}) {
  if (!portfolio?.id) {
    return null;
  }
  return (
    <dialog
      className="spidertattoos-dialog"
      ref={detailDialogRef}
      key={portfolio?.id}
    >
      <section className="spidertattoos-dialog__inner">
        <button onClick={() => detailDialogRef?.current?.close()}>X</button>

        {!portfolio || !detailDialogRef?.current ? null : (
          <>
            <div className="instagram-image__final__wrapper">
              <img
                className="instagram-image__final"
                src={`https://cdigital-spaces.ams3.cdn.digitaloceanspaces.com/spidertattoos/portfolios/${portfolio.portfolio_image_file_name}/large/${portfolio.portfolio_image_file_name}`}
              />
            </div>
            <div className="instagram-image__tags__wrapper">
              {(portfolio?.tags ?? [])
                .reduce((acc, tag) => {
                  return acc.concat(tag.split(","));
                }, [])
                .map((tag) => (
                  <span key={tag}>{tag}</span>
                ))}
            </div>
          </>
        )}
      </section>
    </dialog>
  );
}
