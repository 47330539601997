import React from "react";
import InstagramHeader from "../Detail/Detail";
import Modal from "../Detail/Modal/Modal";
import "./List.scss";

export default function InstagramList() {
  //const detailDialogRef = React.useRef();
  //const [selectedDetailModal, setSelectedDetailModal] = React.useState(null);
  const [portfolios, setPortfolios] = React.useState([]);
  const [skip, setSkip] = React.useState(0);
  const [loadedAll, setLoadedAll] = React.useState(false);

  React.useEffect(() => {
    async function loadList() {
      const result = await fetch(`/portfolios/?limit=${12}&skip=${skip}`);
      const resultJson = await result.json();
      if (resultJson?.length < 12) {
        setLoadedAll(true);
      }
      setPortfolios([...portfolios, ...resultJson]);
    }
    loadList();
  }, [skip]);

  return (
    <>
      <h1 id="portfolio-list-title" className={"section-title portfolios"}>
        <span>Trabalhos recentes</span>
      </h1>

      {(portfolios ?? []).map((portfolio) => (
        <InstagramHeader key={portfolio.id} portfolio={portfolio} />
      ))}
      <div className="load-more-wrapper">
        <button
          className="spidertattoos-button load-more"
          disabled={loadedAll}
          onClick={() => setSkip(skip + 12)}
        >
          Carregar mais
        </button>
      </div>
    </>
  );
}
