import React from "react";
import "./Detail.scss";
import Modal from "./Modal/Modal";

export default function InstagramDetail({ portfolio }) {
  const detailDialogRef = React.useRef();
  const openDetailModail = () => {
    detailDialogRef?.current?.showModal();
  };
  return (
    <>
      <Modal
        key={`portfolio-${portfolio?.id}`}
        portfolio={portfolio}
        detailDialogRef={detailDialogRef}
      />

      <div className="instagram-detail">
        <img
          className="instagram-image__final"
          onClick={openDetailModail}
          src={`https://cdigital-spaces.ams3.cdn.digitaloceanspaces.com/spidertattoos/portfolios/${portfolio.portfolio_image_file_name}/thumb/${portfolio.portfolio_image_file_name}`}
        />
      </div>
    </>
  );
}
