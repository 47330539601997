import React from 'react';
export default function Tattoos() {
    return (
      <>
        <h1>Tatuagens</h1>
        <p>ANTES E DEPOIS DE FAZER UMA TATUAGEM</p>
        <p>
                    O Cliente deve ter a certeza do motivo e da zona onde quer realizar a tatuagem.<br /><br />
                    Caso surjam dúvidas em relação ao desenho ou tamanho, estas deverão ser esclarecidas com o tatuador.<br /><br />
                    Deverá alertar-nos no caso de ter algum problema de saúde (p.ex.: problemas cardíacos, diabetes, epilepsia, problemas de pele, alergias, etc.).<br /><br />
                    Tenha em conta que uma tatuagem é para toda a vida. Se não tiver a certeza, não a faça!<br /><br />
                    As máquinas e todo o material são apresentados, montados e deitados fora à frente do cliente. Se tiver alguma questão sobre isso, coloque-a!<br /><br />
          Duas horas após ter feito a tatuagem deve retirar a película e lavá-la com água morna e sabonete neutro. Aplique, de seguida e durante 15 dias, três vezes por dia, a pomada recomendada (Easytattoo) de forma uniforme e em pouca quantidade. A pele deve manter-se bem hidratada.<br /><br />
        NÃO DEVE coçar, tirar crosta, ir à piscina, praia, sauna, apanhar sol ou frequentar o solário durante o período de cicatrização.<br /><br />
        Pode tomar duche, mas sem esfregar directamente a zona tatuada. No final deve secar levemente com a toalha e aplicar a pomada.<br /><br />
        O tempo de cicatrização varia consoante o organismo de cada um, e depende também do tipo de desenho e tamanho. Geralmente, a tatuagem, estará cicatrizada após um mês e meio; deve, contudo, usar um bom protector solar, caso a exponha ao Sol após esse período.<br /><br />
        Se for necessário um retoque (gratuito), deve ser-nos comunicado nos 2 meses seguintes à execução da tatuagem. Após esse período o retoque passa a ser pago.<br /><br />
      </p>
        <p >Qualquer dúvida contacte-nos. Obrigado.</p>

      </>
 )
}
