import React from "react";
import "./Store.scss";

export default function Store() {
  return (
    <section className="loja map-wrapper" id="loja">
      <h1 className="section-title">A Loja</h1>
      <div className="contacts">
        <p className="callout-text slogan">
          Spider Tattoos. Desde 1989. Agora na Rua de Cedofeita.
        </p>
        <div>
          <p className="text-title">Morada</p>
          <p className="callout-text">
            Rua de Cedofeita 389,
            <br />
            4050-181 Porto,
            <br />
            Portugal
          </p>
        </div>
        <div>
          <p className="text-title">Telefone</p>
          <a className="callout-text" href="tel:+351919789984">
            91 978 99 84
          </a>
        </div>
      </div>
      <iframe
        width="100%"
        height="100%"
        frameBorder="0"
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAdVJjdxcg2bT15lMMDwpZ9F2uxpLo29IM&q=Spider+Tattoos,Porto"
        allowFullScreen=""
      />
    </section>
  );
}
