import React from "react";
import PrivacyText from "./Texts/Privacy";
import Cookies from "./Texts/Cookies";
import "./Privacy.scss";

export default function Privacy() {
  const [dialog, setDialog] = React.useState(null);
  const privacyButtonRef = React.useRef();
  const cookiesButtonRef = React.useRef();

  return (
    <section className="privacy">
      <dialog className="spidertattoos-dialog" ref={privacyButtonRef}>
        <div className="wrapper">
          <nav>
            <button
              className="dialog-close"
              onClick={() => privacyButtonRef?.current?.close()}
            >
              X
            </button>
          </nav>
          <section>
            <PrivacyText />
          </section>
        </div>
      </dialog>

      <dialog className="spidertattoos-dialog" ref={cookiesButtonRef}>
        <div className="wrapper">
          <nav>
            <button onClick={() => cookiesButtonRef?.current?.close()}>
              X
            </button>
          </nav>
          <section>
            <Cookies />
          </section>
        </div>
      </dialog>

      <div className="cuidados-a-ter__button__wrapper">
        <button onClick={() => privacyButtonRef?.current?.showModal()}>
          Política de Privacidade
        </button>

        <button onClick={() => cookiesButtonRef?.current?.showModal()}>
          Cookies
        </button>
      </div>
    </section>
  );
}
